import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Layout, Container, Content } from 'layouts';
import {
  TagsBlock,
  CategoriesBlock,
  SEO,
  FormattedDate,
  Button,
  Title,
  Hero,
  CardList,
  PrevNextCards,
} from 'components';
import config from '../../config/site';
import '../styles/prism';
import theme from '../../config/theme';
import site from '../../config/site';
import Facebook from '../assets/facebook.svg';
import Twitter from '../assets/twitter.svg';
import Linkedin from '../assets/linkedin.svg';
import Hatebu from '../assets/hatebu.svg';
import i18n from '../i18n/i18n';



const EntryHeader = styled.div`
  position: relative;
  margin-top: -6.5rem;
  padding: 6.5rem;
  background: ${theme.colors.white.base};

  @media (max-width: ${theme.breakpoints.s}) {
    padding: 3rem 2rem 0;
    margin-right: 1.5rem;
    margin-top: -4rem;
  }

  h1 {
    font-weight: bold;
    line-height: ${theme.lineHeight.heading};
    margin-bottom: 3rem;

    @media (max-width: ${theme.breakpoints.s}) {
      font-size: ${theme.fontSize.xlarge};
      margin-bottom: 2rem;
    }
  }
`;

const EntryInfo = styled.div`
  display: flex;

  @media (max-width: ${theme.breakpoints.s}) {
    flex-direction: column-reverse;
  }
`;

const Info = styled.div`
  flex: 1;
  padding-right: 3rem;

  @media (max-width: ${theme.breakpoints.s}) {
    margin-top: 2rem;
    padding-right: 0;
  }

  h2 {
    color: ${theme.colors.slate.light20};
    font-size: ${theme.fontSize.xsmall};
    font-family: ${theme.fontFamily.heading};
    text-transform: uppercase;
    letter-spacing: ${theme.letterSpacing.medium};
    margin-bottom: 0.75rem;

    @media (max-width: ${theme.breakpoints.s}) {
      display: none;
    }

    + div {
      margin-bottom: 2rem;

      @media (max-width: ${theme.breakpoints.s}) {
        margin-bottom: 1rem;
      }
    }
  }
`;

const TagsContainer = styled.div`
  margin: 1rem 0;
`;

const Description = styled.div`
  flex: 1;
  color: ${theme.colors.slate.light40};
  line-height: ${theme.lineHeight.body};

  @media (max-width: ${theme.breakpoints.s}) {
    font-size: ${theme.fontSize.small};
  }
`;

const EntryMeta = styled.div`
  text-align: center;
  margin-bottom: 6rem;

  @media (max-width: ${theme.breakpoints.s}) {
    margin-bottom: 4rem;
  }
`;

const Reactions = styled.div`
  margin-bottom: 4rem;
  @media (max-width: ${theme.breakpoints.s}) {
    padding: 0 1.5rem;
  }
  h3 {
    color: ${theme.colors.slate.light30};
    letter-spacing: ${theme.letterSpacing.medium};
    font-size: ${theme.fontSize.medium};
  }

  div {
    display: flex;
    justify-content: center;
  }

  a {
    display: block;
    flex: 1;
    margin: 0 0.5rem;
    max-width: 8rem;
    background: ${theme.colors.white.light};
    display: flex;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    transition: ${theme.transitions.default};

    &:hover {
      background: ${theme.colors.white.grey};
    }
  }

  svg {
    height: 1.25rem;
    width: 2rem;
    fill: ${theme.colors.slate.light40};
  }
`;

const EventsSuggestion = styled.section`
  background: ${theme.colors.white.light};
  padding: 4rem 6.5rem 3rem;
  margin-bottom: 6rem;
  display: flex;
  align-items: center;

  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
    padding: 3rem;
    text-align: center;
    margin-bottom: 3rem;
  }

  .title {
    flex: 1;
    display: flex;
    align-items: center;

    @media (max-width: ${theme.breakpoints.s}) {
      display: block;
    }
  }

  .text {
    flex: 1;
    @media (max-width: ${theme.breakpoints.s}) {
      font-size: ${theme.fontSize.small};
    }
  }
`;


const Post = ({
  data,
  pageContext: {
    next,
    prev,
    pathSlug,
    relatedPosts,
    locale,
    defaultSlug,
    supportedLangs,
  }
}) => {

  i18n.changeLanguage(locale);

  /**
   * 記事が存在しない場合は
   * そもそもgatsby-node.jsでページを生成しないので
   * ここでは存在チェックはしない
   */
  const post = data.markdownRemark;
  const image = post.frontmatter.cover.childImageSharp.fluid;
  const title = post.frontmatter.title;
  const date = post.frontmatter.date;
  const html = post.html;

  const nextPrevList = [];
  if (prev) {
    nextPrevList.push({ node: prev });
  }
  if (next) {
    nextPrevList.push({ node: post });
  }

  return (
    <Layout
      headerWhite={true}
      locale={locale}
      defaultSlug={post.fields.defaultSlug}
      supportedLangs={supportedLangs}
    >
      <SEO
        title={`${title} - ${config[locale].title}`}
        desc={post.frontmatter.description || post.excerpt || ' '}
        banner={post.frontmatter.cover.childImageSharp.resize.src}
        pathname={pathSlug}
        article
      />
      <Hero cover={image} />
      <Container type="article">
        <EntryHeader>
          <h1>{post.frontmatter.title}</h1>
          <EntryInfo>
            <Info>
              <h2>{i18n.t("postPage.categoryTitle")}</h2>
              <CategoriesBlock
                size={'small'}
                color={'secondary'}
                list={post.frontmatter.categories || []}
              />
              <h2>{i18n.t("postPage.tagTitle")}</h2>
              <TagsBlock
                size={'small'}
                color={'secondary'}
                list={post.frontmatter.tags || []}
              />
            </Info>
            <Description>{post.frontmatter.description}</Description>
          </EntryInfo>
        </EntryHeader>
      </Container>
      <Container type="article">
        <Content input={html} />
        <EntryMeta>
          <Reactions>
            <h3>{i18n.t("postPage.shareTitle")}</h3>
            <div>
              <a
                href={`http://www.facebook.com/sharer.php?src=bm&u=${site[locale].url}${
                  post.frontmatter.path
                }&t=${post.frontmatter.title}`}
              >
                <Facebook />
              </a>
              <a
                href={`http://twitter.com/share?text=${
                  post.frontmatter.title
                }&url=${site[locale].url}${post.frontmatter.path}&tw_p=tweetbutton`}
              >
                <Twitter />
              </a>
              <a
                href={`http://b.hatena.ne.jp/add?mode=confirm&url=${site[locale].url}${
                  post.frontmatter.path
                }`}
              >
                <Hatebu />
              </a>
              <a
                href={`http://www.linkedin.com/shareArticle?mini=true&url=${
                  site[locale].url
                }${post.frontmatter.path}`}
              >
                <Linkedin />
              </a>
            </div>
          </Reactions>
          <FormattedDate date={post.frontmatter.date} />
          <TagsContainer>
            <TagsBlock list={post.frontmatter.tags || []} />
          </TagsContainer>
        </EntryMeta>
        <EventsSuggestion>
          <div className="title">
            <Title title={i18n.t("postPage.eventTitle")} size="small" />
          </div>
          <div className="text">
            <p>{i18n.t("postPage.eventDescription")}</p>
            <Button title={i18n.t("button.seeAllEvents")} to="/events/" />
          </div>
        </EventsSuggestion>
      </Container>

      <Container type="base">
        <PrevNextCards prev={prev} next={next} />
      </Container>

      {relatedPosts && relatedPosts.length > 0 && (
        <Container type="base">
          <Title title={i18n.t("postPage.relatedArticles")} size="small" />
          <CardList list={relatedPosts.map(post => ({ node: post }))} />
        </Container>
      )}
    </Layout>
  );
};

export default Post;

Post.propTypes = {
  pageContext: PropTypes.shape({
    prev: PropTypes.object,
    next: PropTypes.object,
  }).isRequired,
  data: PropTypes.object.isRequired,
};

/**
 * pathSlugがロケール別になっているので
 * ロケールでフィルターはしない
 */
export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(fields: { slug: { eq: $pathSlug } }) {
      html
      id
      fields {
        localePath
        slug
        defaultSlug
      }
      frontmatter {
        date
        title
        categories
        tags
        description
        path
        cover {
          childImageSharp {
            fluid(
              maxWidth: 1920,
              maxHeight: 1024,
              cropFocus: CENTER,
              quality: 90
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1280, quality: 90) {
              src
            }
          }
        }
      }
    }
  }
`;
